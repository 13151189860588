import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6b707bbb2ec47ed70121ee6fcefb125b/22e7b/online-vs-offline-study.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB4vTHhimSj//EABoQAAMBAAMAAAAAAAAAAAAAAAECAwQRIiT/2gAIAQEAAQUCxyShvnksnUo2dxww89D3/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bh//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/Aaf/xAAbEAABBQEBAAAAAAAAAAAAAAAAAQIREiExQf/aAAgBAQAGPwJ90mBytbpCoUr30d3BZ0//xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhUTFBYXH/2gAIAQEAAT8hJxwVcFmYg3FBrNMAn8TTD5Om79iKK22f/9oADAMBAAIAAwAAABDfz//EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAwEBPxAsppv/xAAWEQEBAQAAAAAAAAAAAAAAAAABEQD/2gAIAQIBAT8QYY67/8QAGxABAQEBAQADAAAAAAAAAAAAAREAIUExYcH/2gAIAQEAAT8QmD00kW34x13SUFQ/d6FN0NVxVZTetFFa8dfvL4Rr0Xf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "online-vs-offline",
            "title": "online-vs-offline",
            "src": "/static/6b707bbb2ec47ed70121ee6fcefb125b/4b190/online-vs-offline-study.jpg",
            "srcSet": ["/static/6b707bbb2ec47ed70121ee6fcefb125b/e07e9/online-vs-offline-study.jpg 200w", "/static/6b707bbb2ec47ed70121ee6fcefb125b/066f9/online-vs-offline-study.jpg 400w", "/static/6b707bbb2ec47ed70121ee6fcefb125b/4b190/online-vs-offline-study.jpg 800w", "/static/6b707bbb2ec47ed70121ee6fcefb125b/e5166/online-vs-offline-study.jpg 1200w", "/static/6b707bbb2ec47ed70121ee6fcefb125b/b17f8/online-vs-offline-study.jpg 1600w", "/static/6b707bbb2ec47ed70121ee6fcefb125b/22e7b/online-vs-offline-study.jpg 4078w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
This is a long read so if you would like to check out only the conclusion click `}<a href="#Conclusion">{`here`}</a></p>
    <hr />
    <h1>{`Introduction`}</h1>
    <p>{`So here is the deal. Due to Covid-19 we have to stay in our homes unless you want to get the coronavirus(in that case go to a therapy session). Learning has been shifted from live face-to-face to online. Now, we have all experienced online learning. We all have at least thought about the question "Is this better than school learning?". This article is not supposed to be a clear cut answer. I have too small a sample size for that. This article will only attempt to list out the PROS and CONS which I and my friends think apply here.`}</p>
    <p>{`I am heavily biased. I am on the side of online education. So, in order to prevent this from being a RANT, I have asked some of my friends to help me out in this quest. So this article will be mix and match of all the points that we have come up with. I also plan on doing a teacher's perspective on this topic (if my teachers agree to it)`}</p>
    <p>{`So lets list out the PROS and CONS. Then we will weight things accordingly. So lets start with Face-to-face studying.`}</p>
    <h2>{`Face-to-Face`}</h2>
    <p>{`PROS`}</p>
    <ul>
      <li parentName="ul">{`Social interaction with friends`}
        <ul parentName="li">
          <li parentName="ul">{`Friends are your backbone. I don't need much to say here. You ain't gonna be much happy if you don't have many friends. That is it.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Social interaction with teachers`}
        <ul parentName="li">
          <li parentName="ul">{`Most teachers aren't good at technology. Change my mind if you can. This isn't even an exaggeration. Not at all. I have experienced this. Everyone has experienced this at least to some extent. They are also not accustomed to teaching in a live class. They naturally teach better in school.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Encouragement to move and exert yourself`}
        <ul parentName="li">
          <li parentName="ul">{`There is no use denying that at home there is much less incentive to move. Just sit in your chair and study. Eat. Sleep. And repeat. I like to think of myself as productive and exercise of course comes under productivity. But in this past month I have skipped about a 7 days of exercise. I did expect better of me.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Labs can be done`}
        <ul parentName="li">
          <li parentName="ul">{`You can't have labs at home. Some nerd people like yours truly have that kind of ability. But that is for the extremes and there aren't that many. Labs are important for the development of certain ideas. It is better to do the double-split experiment than to see a bunch of strips of light in a diagram.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`CONS`}</p>
    <ul>
      <li parentName="ul">{`Fees are higher - Not an issue for a number of people. But for some it is a great one`}
        <ul parentName="li">
          <li parentName="ul">{`It is self explanatory. There are about 4 fans in each classroom in a medium charging school. There are about 4 sections of each class. And there are 12 classes excluding pre-first. Electricity costs grow. Amount of water usage. Maintenance. The school has to pay for that. Therefore fees are higher`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Amount of knowledge gain is limited`}
        <ul parentName="li">
          <li parentName="ul">{`As great as our teachers are, they can only teach us so much. You simply can't say that I can't learn way more from the internet than from my teachers. Wikipedia alone shadows some people. Teachers can be biased, the internet is not conscious.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Synchronous classroom`}
        <ul parentName="li">
          <li parentName="ul">{`Students have to learn as the teacher teaches. If you are left behind, then good luck surviving on your own. If you are already ahead, then waste your time and sit bored`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Inefficient`}
        <ul parentName="li">
          <li parentName="ul">{`One teacher has to give the exact same lecture to so many students. Not only of different sections, but also each year to a new class.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Now lets get into online studying`}</p>
    <h2>{`Online studying`}</h2>
    <p>{`PROS`}</p>
    <ul>
      <li parentName="ul">{`The learning is asynchronous`}
        <ul parentName="li">
          <li parentName="ul">{`Learn anytime anywhere. It doesn't have to be that a student has to learn when the teacher is available. Students can learn on their on leisure.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Fees are lower`}
        <ul parentName="li">
          <li parentName="ul">{`If you come to think of it, you can learn anything for free on the internet. Not limited to school level. Not limited to university level. Everything, including the very bleeding edge can be learnt for free. Just be great at operating a computer which can be learnt by just dabbling with it. You may need to search a lot but there is nothing that you won't find on the internet.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Amount of knowledge gain is finite yet infinite`}
        <ul parentName="li">
          <li parentName="ul">{`While online, you can learn anything that humanity as a whole has managed to discover. You can get degree level understanding for free, while online.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Highly efficient`}
        <ul parentName="li">
          <li parentName="ul">{`Everyone on the globe can learn something simultaneously without the teacher needing to exert themselves more than once. They just write an article or make a video. That is all that is needed`}</li>
        </ul>
      </li>
    </ul>
    <p>{`CONS`}</p>
    <ul>
      <li parentName="ul">{`You can't do labs`}
        <ul parentName="li">
          <li parentName="ul">{`Labs are important for the development of certain ideas. It is better to do the double-split experiment than to see a bunch of strips of light in a diagram. You can't have labs at home. Therefore you will miss out on cool experiments`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Socially distanced (Pun intended)`}
        <ul parentName="li">
          <li parentName="ul">{`Yes you can't meet friends. Sure you can video call but you can't just randomly start beating your friend. That freedom is only available through direct contact.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`You are not moving from your place`}
        <ul parentName="li">
          <li parentName="ul">{`There is no use denying that at home there is much less incentive to move. You can sit there in you chair like a mastermind in a movie using your big brain. Well before exercising your brain you should probably exercise some of your muscles too. There are pretty important.`}</li>
        </ul>
      </li>
    </ul>
    <h2 id="Conclusion">Conclusion</h2>
    <p>{`It is certain that there aren't all the benefits. But I think that we get the gist of it. The biggest benefit of Online learning is being Asynchronous whereas the biggest benefit of offline Face-to-Face studying is socialisation. What do you value more? Learning anytime anywhere for free or socialisation. It is up to you to decide.`}</p>
    <p>{`PS: From this we can see that plain video call classes are the worst as neither can you learn from them anytime anywhere nor can you have social interactions with your friends. If those video calls are recorded then you can make a case for them being online. I will be interested to know your opinions on this. Shoot me an e-mail at `}<a parentName="p" {...{
        "href": "mailto:hi@aproductivenerd.com"
      }}>{`hi@aproductivenerd.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      